<template>
  <div class="inventoryView">
    库存查看
  </div>
</template>

<script>
export default {
  name: "inventoryView"
}
</script>

<style lang="scss">
.inventoryView{

}
</style>